.login-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: $bg;
}

.login-wrp {
  width: 25rem;
}

.login-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  color: $dark-80;
  font-weight: 500;
  h2{
    font-size: 28px;
    font-weight: 600;
    color: $dark-80;
    margin-bottom: 0;
  }
}

.login-form-card {
  border: none;
  background: inherit;
  max-width: calc(100% - 3rem);
  margin: auto;
  .ant-card-body{
    padding: 24px 0 16px;
    border-bottom: 1px solid $dark-30;
  }
}

.forgot-password-wrapper{
  width: 100%;
  text-align: center;
  .forgot-password-btn {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
    color: $main-blue-100;
  }
}

.sign-in-btn{
  height: 46px;
  margin-bottom: 0;
}

.sign-in-spin{
    color: $wht;
    margin-right: 10px;
}

.login-error {
    padding: 5px;
    display: flex;
    justify-content: center;
    color: $custom-red-error;
    font-weight: 500;
    text-align: center;
}

#login {
  .ant-form-item {
    &:last-child{
      margin-bottom: 1rem;
      margin-top: 2.5rem;
    }
  }
}