* {
    font-family: $fnt-1;
}

h1,
h2,
h3,
h4,
h5,
label {
    color: $dark-80;

    &.ant-typography {
        color: $dark-80;
    }
}

p,
a,
section {
    color: $dark-80;
}

.menu-logo {
    margin: 30px 18px 8px;
    width: calc(50% - 18px);

    img {
        width: 100%;
    }
}
.top-menu-logo{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    .mobile-drawer-toggler{
        display: inline-block;
        margin-right: 10px;
        + img{
            display: none;
        }
    }
}

.side-menu-logo{
    padding: 24px;
    text-align: center;
}

.ant-layout {
    background-color: $bg;

    .ant-layout-header {
        border-bottom: 1px solid $dark-30;
        padding: 0 30px;
        text-align: right;

        @media( max-width: 767px) {
            padding: 0 16px;
        }

        .login-info {
            .profile-link {
                text-decoration: none;
                color: inherit;
                font-weight: 600;
            }

            span {
                display: inline-block;
                width: 40px;
                height: 40px;
                border-radius: 40px;
                background-color: $custom-red-1;
                vertical-align: middle;
                margin-left: 0.5rem;
                text-align: center;
                color: $wht;
                line-height: 40px;
                font-size: 16px;
                font-weight: bold;
                margin-top: -5px;
            }
        }
    }

    .ant-layout-sider {
        background: $wht;
        min-height: 100vh;
        border-right: 1px solid $dark-30;
    }

    .ant-menu-item {
        color: $main-blue-2;
        font-size: 14px;
        margin: 24px 0 !important;
        padding-left: 18px !important;
        height: 24px;
        line-height: 24px;

        a {
            color: inherit;
            font-size: inherit;
            font-weight: 600;
        }

        &.ant-menu-item-selected {
            background-color: $trnsp;
            color: $main-blue-100;

            a {
                color: inherit;
            }

            &:after {
                right: initial;
                left: 0;
                border-color: $main-blue-100;
                border-width: 2px;
            }
        }

        .ant-menu-item-icon {
            margin-right: 14px;
            font-size: 16px;
            color: inherit;
            vertical-align: text-top;
        }
    }

    .grid-search-box {
        width: 400px;
    }

    .grid-options {
        width: 100%;
        display: flex;
        justify-content: space-between;

        >.ant-space {
            margin-bottom: 8px;

            >.ant-space-item {
                margin-bottom: 8px;
            }
        }

        .grid-options--search {
            .ant-space-item {
                &:first-child {
                    width: calc(100% - 125px);
                    max-width: 400px;
                }
            }
        }
    }

    .main-content-wrapper {
        padding: 24px 30px;
    }

    &.layout--mobile {
        .main-content-wrapper {
            padding: 24px 16px;
        }

        .ant-layout-header {
            display: flex;
            justify-content: space-between;
            padding: 0 16px;
        }

        .grid-search-box {
            width: 100%;
        }

        .grid-options {
            display: flex;
            flex-direction: column-reverse;
            justify-content: space-between;
            align-items: center;

            >.ant-space {
                width: 100%;
                justify-content: center;

                @media (max-width: 400px) {
                    flex-wrap: wrap;
                }
            }

            .grid-options--search {
                .ant-space-item {
                    &:first-child {
                        width: calc(100% - 125px);
                        max-width: 400px;
                    }

                    @media (max-width: 400px) {
                        width: 100% !important;
                        margin-right: 0 !important;

                        .btn--primary {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.ant-drawer {
    &.side-drawer {
        .ant-drawer-content {
            .ant-drawer-body {
                padding: 0;
            }

            .ant-menu-item {
                color: $main-blue-2;
                font-size: 14px;
                margin: 24px 0 !important;
                padding-left: 18px !important;
                height: 24px;
                line-height: 24px;

                a {
                    color: inherit;
                    font-size: inherit;
                    font-weight: 600;
                }

                &.ant-menu-item-selected {
                    background-color: $trnsp;
                    color: $main-blue-100;

                    a {
                        color: inherit;
                    }

                    &:after {
                        right: initial;
                        left: 0;
                        border-color: $main-blue-100;
                        border-width: 2px;
                    }
                }

                .ant-menu-item-icon {
                    margin-right: 14px;
                    font-size: 16px;
                    color: inherit;
                    vertical-align: text-top;
                }
            }
        }
    }
}

// .table-wrapper{
//     .ant-table{
//         .ant-table-container{
//             width: 100%;
//             overflow-x: auto;
//             .ant-table-content{
//                 min-width: 1000px;
//                 width: 100%;
//                 .ant-table-cell{
//                     .ant-badge-status{
//                         white-space: nowrap;
//                     }
//                     .anticon-star{
//                         width: 0;
//                     }
//                 }
//             }
//         }
//     }
// }

.ant-select {
    &.ant-select-single {
        .ant-select-selector {
            height: 40px;
            padding: 5px 20px 5px 12px;
            border-radius: 6px;
            min-width: 150px;
        }
    }
}

.ant-input-search,
.grid-search-box {
    max-width: 100%;

    .ant-input-group.ant-input-wrapper {
        .ant-input-affix-wrapper {
            height: 40px;
            padding: 8px 20px 8px 12px;
            border-radius: 6px !important;
        }

        .ant-input-group-addon {
            .ant-input-search-button {
                height: 38px;
                border-color: $trnsp;
                // background-color: $trnsp;
                border-radius: 0 6px 6px 0;
                margin-left: -35px;
                position: relative;
                z-index: 1;
            }

            &:last-child {
                left: 0;
            }
        }
    }

    &.ant-input-affix-wrapper {
        height: 40px;
        padding: 8px 20px 8px 12px;
        border-radius: 6px !important;
    }
}

.btn {
    height: 40px;
    border-radius: 6px;
    border: 1px solid $trnsp;
    min-width: 100px;

    +.btn {
        margin-left: 0.5rem;
    }

    span {
        color: inherit;
    }

    &.btn--primary {
        color: $wht;
        background-color: $main-blue-100;
        border-color: $main-blue-100;

        &:hover,
        &:focus {
            color: $wht;
            background-color: $main-blue-100;
            border-color: $main-blue-100;
        }
    }

    &.btn--o-primary {
        color: $main-blue-100;
        background-color: $wht;
        border-color: $main-blue-3;

        &:hover,
        &:focus {
            color: $main-blue-100;
            background-color: $wht;
            border-color: $main-blue-100;
        }
    }

    &.btn--info {
        color: $wht;
        background-color: $main-blue-100;
        border-color: $main-blue-100;

        &:hover,
        &:focus {
            color: $wht;
            background-color: $main-blue-100;
            border-color: $main-blue-100;
        }
    }

    &.btn--warning {
        color: $wht;
        background-color: $custom-orange-1;
        border-color: $custom-orange-1;

        &:hover,
        &:focus {
            color: $wht;
            background-color: $custom-orange-1;
            border-color: $custom-orange-1;
        }
    }

    &.btn--danger {
        color: $wht;
        background-color: $custom-red-1;
        border-color: $custom-red-1;

        &:hover,
        &:focus {
            color: $wht;
            background-color: $custom-red-1;
            border-color: $custom-red-1;
        }
    }

    &.btn--text {
        height: initial;
        color: inherit;
        background-color: $wht;
        border: none;
        width: 100%;
        text-align: left;
        padding: 0 0.5rem;

        &:hover,
        &:focus {
            color: inherit;
            background-color: $dark-30;
            border: none;
        }
    }
}

.link-btn {
    display: inline-block;
    height: initial;
    text-decoration: none;
    background: $trnsp;
    margin: 0 0.5rem;
    padding: 0;
    border: none;
    border-bottom: 1px solid $trnsp;

    span {
        color: inherit;
    }

    &:hover,
    &:focus {
        background: $trnsp !important;
    }

    &.link-btn--default {
        color: $dark-70;

        &:hover,
        &:focus {
            color: $dark-70;
            border-color: $dark-70 !important;
        }
    }

    &.link-btn--danger {
        color: $custom-red-error;

        &:hover,
        &:focus {
            color: $custom-red-error;
            border-color: $custom-red-error !important;
        }
    }
}

.ant-space-item {
    >input.ant-input {
        border-color: $dark-30;
        min-height: 40px;
        border-radius: 6px;
    }
}

.ant-picker {
    border-color: $dark-30;
    min-height: 40px;
    border-radius: 6px;
}

.quill {
    .ql-toolbar {
        &.ql-snow {
            border-color: $dark-30;
            border-radius: 6px 6px 0 0;
        }
    }

    .ql-container {
        &.ql-snow {
            border-color: $dark-30;
            border-radius: 0 0 6px 6px;
        }
    }
}

.status {
    white-space: nowrap;

    &:before {
        content: '';
        position: relative;
        display: inline-block;
        line-height: inherit;
        width: 10px;
        height: 10px;
        border-radius: 20px;
        margin-right: 2px;
    }

    &.status--active,
    &.status--published {
        &:before {
            background-color: $custom-green-1;
        }
    }

    &.status--inactive,
    &.status--scheduled {
        &:before {
            background-color: $custom-orange-2;
        }
    }
}

.ant-form-item-explain-error {
    color: $custom-red-error;
}

.select-text {
    color: $dark-70;
    display: inline-block;
    padding: 0 0.5rem;
}

.divider-vertical {
    display: inline-block;
    border-left: 1px solid $gry-d9;
    height: 50px;
    margin: -20px 0.5rem;
}

// .ant-table-wrapper{
//     background: $wht;
//     border: 1px solid $gry-d9;
//     border-radius: 12px;
//     overflow: hidden;
//     .ant-table{
//         border-bottom: 1px solid $gry-d9;
//         .ant-table-title{
//             padding: 0;
//             background-color: $dark-20;
//             .ant-space{
//                 padding: 1rem;
//             }
//         }
//         .ant-table-container{
//             .ant-table-content{
//                 table{
//                     th {
//                         background-color: $wht;
//                         border-bottom: 1px solid $gry-d9;
//                         &.ant-table-selection-column{
//                             padding-left: 20px;
//                         }
//                     }
//                     tr{
//                         td{
//                             background-color: $wht;
//                             &.ant-table-selection-column{
//                                 padding-left: 20px;
//                             }
//                         }
//                         &:nth-child(2n - 1) {
//                             td{
//                                 background-color: $dark-10;
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//     }
//     .ant-pagination{
//         margin: 12px;
//         .ant-pagination-item{
//             border: none;
//             background-color: $dark-10;
//             a{
//                 color: $dark-60;
//             }
//             &.ant-pagination-item-active{
//                 background-color: $main-blue-100;
//                 a{
//                     color: $wht;
//                 }
//             }
//         }
//         .ant-pagination-prev, .ant-pagination-next{
//             .ant-pagination-item-link{
//                 border: none;
//                 background-color: $dark-10;
//                 color: $dark-60;
//             }
//         }
//     }
// }

.loading-wrapper {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-popover {
    .ant-popover-inner {
        .ant-popover-inner-content {
            padding: 8px;
        }
    }
}

.ant-card {
    margin-bottom: 1.5rem;

    .ant-card-head {
        .ant-card-head-title {
            font-weight: 600;
        }
    }
}

.more-settings {
    font-size: 28px;
    font-weight: bold;
}

.tenant-col {
    max-width: 130px;
    display: block;
}

.ant-form {
    .ant-form-item {
        margin-bottom: 1.5rem;

        input[type=text],
        input[type=password],
        input[type=email],
        input[type=number] {
            height: 40px;
            padding: 5px 20px 5px 12px;
            border-radius: 6px;
        }
    }
}

.ant-input-group {
    .ant-input-group-addon {
        border-radius: 6px 0 0 6px;

        +input {
            border-radius: 0 6px 6px 0 !important;
        }
    }
}

.ant-input-password {
    padding: 0 11px 0 0;
    border-radius: 6px;
}

.alert {
    padding: 1rem 1.5rem;
    border-radius: 6px;
    border: 1px solid $trnsp;
    margin-bottom: 1.5rem;

    &.alert-info {
        background-color: $custom-blue-info;
        color: $main-blue-100;
        border-color: $custom-blue-info-stroke;
    }
}

.banner-body {
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.banner {
    width: 60%;
    text-align: center;
    height: 5rem;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
}

.epar__hazard__map__legend {
    background: $wht;
    border: 1px solid $gry-d;
    border-radius: 6px;
    margin: 16px 0 0;
    padding: 16px 16px 0;
    div {
      padding: 0 10px 16px 0;
      display: inline-block;
    }
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

.table-wrapper{
    width: 100%;
    overflow: auto;
}
.ant-row{
    > .ant-col-12{
        &:first-child {
            padding-right: 12px;
        }
        &:last-child {
            padding-left: 12px;
        }
    }
}
@media(max-width: 767px) {
    .ant-card-body{
        padding: 16px;
    }
}

.steps-container {
    margin-top: 20px;
    margin-bottom: 40px;
    .ant-steps-item{
        .ant-steps-item-content {
            .ant-steps-item-description {
                margin-left: -30px;
                margin-right: -30px;
                font-weight: 500;
                @media only screen and (max-width: 1365px){
                margin-left: 0px;
                margin-right: 0px;
                }
            }
        }
    }
}

.steps-container-mobile {
    div{
        .ant-steps-item{
            .ant-steps-item-content {
                .ant-steps-item-description {
                    width: 90%;
                }
            }
        }
    }
}

.search-bar {
    .ant-input-wrapper {
        .ant-input{
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }
}

.certification-summary-view {
    .ant-collapse-header{
        font-size: 16px !important;
        font-weight: 600 !important;
    }
}

.pre-certification-card-progress {
    .ant-card-head{
        min-height: 30px;
        .ant-card-head-wrapper{
            .ant-card-head-title{
                font-size: 13px!important;
                font-weight: 600;
                text-align:start !important;
                padding-top: 10px;
                padding-bottom: 10px;
            }
        }
    }
}

.audubon-link-text {
    color: #40a9ff;
    text-decoration: underline;
    margin-bottom: 0;
    cursor: pointer;
}

.input-group-container {
    display: flex;
    flex-direction: row;
    .answer-field {
        font-weight: 500;
        margin: 0 50px 0 5px;
    }
}

.multi-answer-container {
    display: flex;
    flex-direction: column;
}